const ro = {
  "onb-screen1-title": "Bun venit la ProudOf,",
  "onb-screen1-subtitle": "aplicația axată pe dezvoltare personală",
  "onb-screen1-text": "Cultivă-ți dragostea de sine adăugând intrări în jurnal și crescând un arbore simbolic al progresului.",

  "onb-screen2-title": "Adăugând intrări arborele va crește",
  "onb-screen2-subtitle": "La fiecare 15 intrări, arborele se va dezvolta reflectând astfel evoluția și realizările tale.",
  
  "onb-screen3-title": "Personalizează-ți arborele",
  "onb-screen3-subtitle": "Personalizează aspectul arborelui tău cu accesorii din shop.",

  "onb-skip": "Sari peste",

  // Auth
  "auth-create-account-title": "Crează un cont gratuit",
  "auth-email-address": "Adresă de e-mail",
  "auth-password": "Parolă",
  "auth-password-repeat": "Repetă parola",
  
  // Auth err
  "auth-err-invalid-email": "Vă rugăm să introduceți o adresă de e-mail validă",
  "auth-err-email-in-use": "Adresa de e-mail este deja folosită",
  "auth-err-blank-password": "Vă rugăm să introduceți o parolă",
  "auth-err-password-6-char": "Parola trebuie să aibă cel puțin 6 caractere",
  "auth-err-passwords-not-same": "Parolele nu sunt la fel",
  "auth-err-invalid-login-details": "Date de conectare nevalide",
  
  "auth-create-account-main-cta": "Creează cont gratuit",
  "auth-already-have-account": "Ai deja un cont?",
  "auth-sign-in-cta": "Conectare",
  
  "auth-terms-part1": "Prin înscriere, indicați că ați citit și sunteți de acord cu",
  "auth-terms": "Termeni și condiții",
  "auth-terms-and": "și",
  "auth-privacy": "Politica de confidențialitate",
  
  "auth-signin-title": "Conectați-vă la contul dvs.",
  "auth-signin-forgot-pass-cta": "Aţi uitat parola?",
  "auth-signin-main-cta": "Log in",
  "auth-signin-create-cta": "Nu ai un cont? <1>Înscrie-te</1> gratuit",
  
  "auth-forgot-title": "Aţi uitat parola",
  "auth-forgot-main-cta": "Resetează parola",
  "auth-forgot-already-account": "Ai deja un cont?",
  "auth-forgot-sign-in": "Conectare",

  "home-main-cta": "Adaugă o intrare",
  "home-secondary-cta": "Intrări anterioare",
  
  "notification-daily-checkin": "Ați primit {{dailyCheckinHeartsAward}} puncte pentru check-in zilnic.",
  
  "add-post-title": "Astăzi sunt mândru de:",

  "mon": "LUN",
  "tue": "MAR",
  "wed": "MIE",
  "thu": "JOI",
  "fri": "VIN",
  "sat": "SAM",
  "sun": "DUM",

  "jan": "Ianuarie",
  "feb": "Februarie",
  "mar": "Martie",
  "apr": "Aprilie",
  "may": "Mai",
  "jun": "Iunie",
  "jul": "Iulie",
  "aug": "August",
  "sep": "Septembrie",
  "oct": "Octombrie",
  "nov": "Noiembrie",
  "dec": "Decembrie",

  "loading": "Se încarcă...",
  
  "posts-no-entries-title": "Nu există încă intrări.",
  "posts-no-entries-cta": "Adaugă prima ta intrare",
  
  "edit-entry-title": "Editați intrarea:",
  "edit-entry-delete-cta": "Ștergeți intrarea",
  "edit-entry-delete-modal-title": "Sigur doriți să ștergeți această intrare?",
  "edit-entry-delete-modal-yes-cta": "Da",
  "edit-entry-delete-modal-no-cta": "Nu",
  
  "tree-collection-title": "Colecția ta de arbori",
  "tree-collection-empty-msg": "Colecția ta de arbori este goală în prezent. Arborii care ating creșterea maximă vor fi salvați automat în colecția ta.",

  "profile-title": "Statisticile tale",
  "profile-total-entries": "Intrări totale",
  "profile-total-day": "Zi",
  "profile-total-days": "Zile",
  "profile-total-trees": "Arbori adunați",
  "profile-tree-status": "Starea actuală a arborelui",
  "profile-tree-status-1": "Tânăr",
  "profile-tree-status-2": "Adult",
  "profile-tree-status-3": "Secular",
  "profile-btn-send-feedback": "Trimite feedback",
  "profile-btn-how-it-works": "Cum funcționeazã",
  "profile-btn-logout": "Deconectare",
  "profile-btn-delete-account": "Șterge contul",
  "profile-btn-change-language": "Schimbă limba",
  "profile-btn-shop": "Shop",

  // Delete account
  "modal-delete-account-title": "Sigur doriți să vă ștergeți contul?",
  "modal-delete-account-subtitle": "Contul dvs. și toate datele dvs. vor fi șterse.",
  "modal-delete-account-text": "Introduceți parola pentru a vă șterge contul.",
  "password": "Parola",
  "modal-delete-account-invalid-credentials": "Date de conectare nevalide",
  "modal-delete-account-main-cta": "Șterge cont",
  "modal-delete-account-cancel-cta": "Nu",
  
  "shop-tab-title": "Shop",
  "customize-tab-title": "Personalizează",
  
  "shop-page-title": "Păsări",
  
  "customize-page-save-cta": "Salvează modificările",
  "customize-page-title": "Personalizează-ți arborele",
  "customize-page-no-addon-cta": "Cumpără accesoriu",

  "offline-notification": "Sunteți offline, este posibil ca unele dintre funcții să nu fie salvate fără o conexiune activă la internet",

  "general-error": "Ceva n-a mers bine. Te rugăm să încerci din nou mai târziu.",
  "general-notification-auth-reset-link-sent": "Un link de resetare a fost trimis la adresa ta de e-mail.",

  "profile-no-account-text" : "Datele tale nu sunt asociate în prezent cu niciun cont și sunt accesibile exclusiv pe acest dispozitiv. În cazul dezinstalării aplicației sau pierderii dispozitivului, datele tale vor fi irecuperabile. Creează un cont gratuit pentru a-ți salva datele și a le accesa pe alte dispozitive.",

  "auth-slogan": "Îmbrațișează-ți povestea: Aplicație de jurnal pentru stimularea încrederii in sine, dezvoltare și bunăstare mentală.",
  "auth-continue-without-account": "Continuă fără a crea un cont",
  "auth-continue-without-account-note": "Te rugăm să ții cont de faptul că a continua fără un cont va duce la pierderea datelor tale la ștergerea aplicației. Poți opta să creezi un cont ulterior de pe pagina de profil.",

  "inspiration-1": "ex. M-am relaxat cu o meditație de 15 minute astăzi.",
  "inspiration-2": "ex. Nu am sarit peste micul dejun în dimineața asta.",
  "inspiration-3": "ex. Am făcut cardio la sală, am alergat 5 km pe banda de alergat.",
  "inspiration-4": "ex. Am stat în parc cu câinele meu o oră.",
  "inspiration-5": "ex. Am renunțat la fast food și am pregătit o cină gustoasă în schimb.",
  "inspiration-6": "ex. Am citit 30 de pagini, mai mult decât mi-am propus astazi.",
  "inspiration-7": "ex. Am avut o discuție plăcută la telefon cu un prieten.",
}

export default ro;