import { useState, useEffect, useContext } from 'react';

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { getDatabase, ref, update, child, push, increment } from "firebase/database";

import StoreContext from '../../util/StoreContext';
import AuthContext from '../../util/AuthContext';
import { StoreType,AuthStoreType } from '../../util/types';

import styles from './Tree.module.css';

import treeImages from '../../util/treeImages';
import addonImages from '../../util/addons';

import treeLevelStates from '../../util/treeLevelStates';

const Tree = () => {
  const [fill, setFill] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  
  const [treeType, setTreeType] = useState(1); // default to level 1 tree

  const { treeLevelFromDB, selectedAddon, postAddedFromApp, setPostAddedFromApp, setStoreTreeType } = useContext(StoreContext) as StoreType;
  const { userId } = useContext(AuthContext) as AuthStoreType;

  const db = getDatabase();

  useEffect(() => {
    setStoreTreeType(treeType);
  }, [treeType])

  useEffect(() => {
    if (treeLevelFromDB !== null) {
      setTimeout(() => {
        calculateTreeType();
        
        // animate progressbar to specific tree value
        const level = treeLevelStates[treeLevelFromDB];
        setIsLoading(false);
        setFill(level);
      }, 500);
    }
  }, [treeLevelFromDB]);

  const calculateTreeType = () => {
    if (treeLevelFromDB === null) return;

    if (treeLevelFromDB >= 0 && treeLevelFromDB < 15) {
      setTreeType(1);
      
    // level 2 tree
    } else if (treeLevelFromDB > 15 && treeLevelFromDB < 30) {
      setTreeType(2);

    // level 3 tree
    } else if (treeLevelFromDB > 30 && treeLevelFromDB < 45) {
      setTreeType(3);
    }

    if (treeLevelFromDB === 15) {
      setTimeout(() => {       
        // reset progressbar to 0 in 1ms in order to not see animation
        // progressBarRef.current.animate(0, 1);
        setFill(0);
        setTreeType(2);
      }, 1200);
    }

    if (treeLevelFromDB === 30) {
      setTimeout(() => {       
        // reset progressbar to 0 in 1ms in order to not see animation
        // progressBarRef.current.animate(0, 1);
        setFill(0);
        setTreeType(3);
      }, 1200);
    }

    // user completed final tree, reset everything to 0 with a small delay and show completion notification
    if (treeLevelFromDB === 45) {
      setTreeType(3);

      setFill(0);

      /*
        NOTE:
        We reset the tree only if the post was added from the WEB app.
        If the user has the web app and the phone app open at the same time but he adds the post from the phone,
        this listener will also fire. We do not want this to happen, as it would result in doubling the calls and potential unwanted behaviour
        So we only fire this below only if the post was added from the WEB app. A similar verification is also present in the phone app.
      */
      if (postAddedFromApp) {
        // post tree in collection of completed trees
        const newPostKey = push(child(ref(db), 'trees')).key;
        const addonToDB = selectedAddon || 1000;
        const updates: {[key: string]: any} = {};

        // reset treeLevel & addon
        updates[`users/${userId}/treeLevel`] = 0;
        updates[`users/${userId}/selectedAddon`] = null;
        updates[`users/${userId}/collectionNo`] = increment(1);
        updates[`trees/${userId}/${newPostKey}`] = {addon: addonToDB};
        update(ref(db), updates);
      }
    }

    // reset flag after the poste was added
    setPostAddedFromApp(false);
  }

  return (
    <div className={styles.treeContainer}>
      <CircularProgressbarWithChildren
        value={fill}
        strokeWidth={3}
        background={true}
        styles={buildStyles({
          // Rotation of path and trail, in number of turns (0-1)
          rotation: 0.50,
      
          // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
          strokeLinecap: 'butt',
      
          // How long animation takes to go from one percentage to another, in seconds
          pathTransitionDuration: 0.5,
      
          // Can specify path transition in more detail, or remove it entirely
          // pathTransition: 'none',
      
          // Colors
          pathColor: '#EFA46B',
          textColor: '#f88',
          trailColor: '#5392BD',
          backgroundColor: '#fff',
        })}
      >
        <div className={styles.treeWrap}>
          {!isLoading &&
            <div className={styles.treeImages}>
              {selectedAddon &&
                <img loading='lazy' src={addonImages[selectedAddon]} alt="addon" width="30" className={styles.addon} />
              }
              <img loading='lazy' src={treeImages[selectedAddon || 'default'][treeType].leaves1} alt="tree leaves" width="220" />
              <img loading='lazy' src={treeImages[selectedAddon || 'default'][treeType].leaves2} alt="tree leaves" width="220" />
              <img loading='lazy' src={treeImages[selectedAddon || 'default'][treeType].trunk} alt="tree leaves" width="220" />
            </div>
          }
        </div>
      </CircularProgressbarWithChildren>
    </div>
  )
}

export default Tree;