import { initializeApp, } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth } from "firebase/auth";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyClN5HFLKzzr9FoTLId811l2HWNSbtix-s",
  authDomain: "proudof-e67f5.firebaseapp.com",
  projectId: "proudof-e67f5",
  storageBucket: "proudof-e67f5.appspot.com",
  messagingSenderId: "920030053064",
  appId: "1:920030053064:web:3c62f906ae9c1f3a919bfd",
  measurementId: "G-PS74MX23ES",
  databaseURL: "https://proudof-e67f5-default-rtdb.europe-west1.firebasedatabase.app/",
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);

export const authenticateFirebase = () => getAuth(app);

export default app;