

interface TreeImagesType {
  [key: string]: {
    [key: number]: {
      [key: string]: string
    }
  }
}


const treeImages: TreeImagesType = {
  bird1: {
    1: {
      leaves1: require('../assets/tree-small/small-tree-leaves1-color1.png'),
      leaves2: require('../assets/tree-small/small-tree-leaves2-color1.png'),
      trunk: require('../assets/tree-small/small-tree-trunk.png'),
    },
    2: {
      leaves1: require('../assets/tree-medium/medium-tree-leaves1-color1.png'),
      leaves2: require('../assets/tree-medium/medium-tree-leaves2-color1.png'),
      trunk: require('../assets/tree-medium/medium-tree-trunk.png'),
    },
    3: {
      leaves1: require('../assets/tree-big/big-tree-leaves1-color3.png'),
      leaves2: require('../assets/tree-big/big-tree-leaves2-color3.png'),
      trunk: require('../assets/tree-big/big-tree-trunk.png'),
    },
  },
  bird2: {
    1: {
      leaves1: require('../assets/tree-small/small-tree-leaves1-color2.png'),
      leaves2: require('../assets/tree-small/small-tree-leaves2-color2.png'),
      trunk: require('../assets/tree-small/small-tree-trunk.png'),
    },
    2: {
      leaves1: require('../assets/tree-medium/medium-tree-leaves1-color2.png'),
      leaves2: require('../assets/tree-medium/medium-tree-leaves2-color2.png'),
      trunk: require('../assets/tree-medium/medium-tree-trunk.png'),
    },
    3: {
      leaves1: require('../assets/tree-big/big-tree-leaves1-color2.png'),
      leaves2: require('../assets/tree-big/big-tree-leaves2-color2.png'),
      trunk: require('../assets/tree-big/big-tree-trunk.png'),
    },
  },
  bird3: {
    1: {
      leaves1: require('../assets/tree-small/small-tree-leaves1-color3.png'),
      leaves2: require('../assets/tree-small/small-tree-leaves2-color3.png'),
      trunk: require('../assets/tree-small/small-tree-trunk.png'),
    },
    2: {
      leaves1: require('../assets/tree-medium/medium-tree-leaves1-color3.png'),
      leaves2: require('../assets/tree-medium/medium-tree-leaves2-color3.png'),
      trunk: require('../assets/tree-medium/medium-tree-trunk.png'),
    },
    3: {
      leaves1: require('../assets/tree-big/big-tree-leaves1-color1.png'),
      leaves2: require('../assets/tree-big/big-tree-leaves2-color1.png'),
      trunk: require('../assets/tree-big/big-tree-trunk.png'),
    },
  },
  bird4: {
    1: {
      leaves1: require('../assets/tree-small/small-tree-leaves1-color4.png'),
      leaves2: require('../assets/tree-small/small-tree-leaves2-color4.png'),
      trunk: require('../assets/tree-small/small-tree-trunk.png'),
    },
    2: {
      leaves1: require('../assets/tree-medium/medium-tree-leaves1-color4.png'),
      leaves2: require('../assets/tree-medium/medium-tree-leaves2-color4.png'),
      trunk: require('../assets/tree-medium/medium-tree-trunk.png'),
    },
    3: {
      leaves1: require('../assets/tree-big/big-tree-leaves1-color4.png'),
      leaves2: require('../assets/tree-big/big-tree-leaves2-color4.png'),
      trunk: require('../assets/tree-big/big-tree-trunk.png'),
    },
  },
  bird5: {
    1: {
      leaves1: require('../assets/tree-small/small-tree-leaves1-color5.png'),
      leaves2: require('../assets/tree-small/small-tree-leaves2-color5.png'),
      trunk: require('../assets/tree-small/small-tree-trunk.png'),
    },
    2: {
      leaves1: require('../assets/tree-medium/medium-tree-leaves1-color5.png'),
      leaves2: require('../assets/tree-medium/medium-tree-leaves2-color5.png'),
      trunk: require('../assets/tree-medium/medium-tree-trunk.png'),
    },
    3: {
      leaves1: require('../assets/tree-big/big-tree-leaves1-color5.png'),
      leaves2: require('../assets/tree-big/big-tree-leaves2-color5.png'),
      trunk: require('../assets/tree-big/big-tree-trunk.png'),
    },
  },
  default: {
    1: {
      leaves1: require('../assets/tree-small/small-tree-leaves1.png'),
      leaves2: require('../assets/tree-small/small-tree-leaves2.png'),
      trunk: require('../assets/tree-small/small-tree-trunk.png'),
    },
    2: {
      leaves1: require('../assets/tree-medium/medium-tree-leaves1.png'),
      leaves2: require('../assets/tree-medium/medium-tree-leaves2.png'),
      trunk: require('../assets/tree-medium/medium-tree-trunk.png'),
    },
    3: {
      leaves1: require('../assets/tree-big/big-tree-leaves1.png'),
      leaves2: require('../assets/tree-big/big-tree-leaves2.png'),
      trunk: require('../assets/tree-big/big-tree-trunk.png'),
    },
  }
}

export default treeImages;