import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";

import styles from './Awards.module.css';

import AuthContext from "../../util/AuthContext";
import { AuthStoreType, TreeCollectionType } from "../../util/types";

import {
  getDatabase,
  ref,
  onValue,
  query,
} from "firebase/database";

import Loading from "../../components/Loading/Loading";
import TreeCollectible from "../../components/TreeCollectible/TreeCollectible";

const Awards = () => {

  const { t } = useTranslation();

  const [trees, setTrees] = useState<TreeCollectionType[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { userId } = useContext(AuthContext) as AuthStoreType;

  useEffect(() => {

    const db = getDatabase();
    
    const treeLevelRef = query(
      ref(db, `trees/${userId}`)
    );
    onValue(treeLevelRef, (snapshot) => {
      const treesArr: TreeCollectionType[] = [];
      
      snapshot.forEach(tree => {
        treesArr.push(tree.val());
      });

      setTrees(treesArr);
      setIsLoading(false);
    });
  }, [userId]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="subpage-wrap">
      <div className="subpage">
        <h1>{t('tree-collection-title')}</h1>

        <div className="container-blue">
          {
            trees.length === 0
            ?
              <>{t('tree-collection-empty-msg')}</>
            :
              <div className={styles.treeCollection}>
                {trees.map((tree, index) => 
                  <TreeCollectible key={index} addon={tree.addon} />
                )}
              </div>
          }
          
        </div>
      </div>
    </div>
  )
}

export default Awards;