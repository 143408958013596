import { useState, useEffect, useContext } from 'react';

import { getDatabase, ref, update, increment, child, push } from "firebase/database";

import AuthContext from '../../util/AuthContext';
import StoreContext from '../../util/StoreContext';

import { AuthStoreType, StoreType, QuoteType } from '../../util/types';

import styles from './AddPost.module.css';
import { IoIosAddCircleOutline } from "react-icons/io";

import { useTranslation } from 'react-i18next';

import quotes from '../../util/quotes';
import inspiration from '../../util/inspiration';

const AddPost = () => {
  const { t } = useTranslation();

  const [entry, setEntry] = useState('');
  const [disableCta, setDisableCta] = useState(false);
  const [quote, setQuote] = useState<QuoteType>();
  const [placeholderInspiration, setPlaceholderInspiration] = useState('');

  const db = getDatabase();

  // [TODO] hearts
  // const {userId, hearts, setHearts} = useContext(StoreContext) as AuthStoreType;
  const {userId} = useContext(AuthContext) as AuthStoreType;

  const { postAddedFromApp, setPostAddedFromApp } = useContext(StoreContext) as StoreType;

  useEffect(() => {
    const quoteNo = Math.floor(Math.random() * 6) + 1;
    setQuote(quotes.en[quoteNo]);

    const inspirationNo = Math.floor(Math.random() * 7) + 1;
    setPlaceholderInspiration(t(inspiration[inspirationNo]));
  }, []);

  useEffect(() => {
    if (postAddedFromApp) {
      writeUserData();
    }
  }, [postAddedFromApp]);

  function writeUserData() {
    const timestamp = Date.now();

    if (entry.length === 0) {
      setDisableCta(false);
      
      return;
    }
    
    // user receives 1 heart per post
    // setHearts(Number(hearts) + 1);

    const newPostKey = push(child(ref(db), 'posts')).key;
    
    const updates: {[key: string]: any} = {}

    updates[`posts/${userId}/${newPostKey}`] = {post: entry, timestamp: timestamp, negativeTimestamp: -timestamp};
    updates[`users/${userId}/noOfEntries`] = increment(1);
    updates[`users/${userId}/lastPostDate`] = timestamp;
    updates[`users/${userId}/treeLevel`] = increment(1);
    
    update(ref(db), updates);
    setDisableCta(false);
    setEntry('');
    
  }

  const addPostToDB = () => {
    setDisableCta(true)
    setPostAddedFromApp(true);
  }

  return (
    <div className={styles.addPostPage}>
      <div className={styles.quote}>
        {quote?.quote}
        <div className={styles.author}>- {quote?.author}</div>
      </div>
      <h2 className={styles.test}>{t('add-post-title')}</h2>
      <textarea value={entry} onChange={(e) => {setEntry(e.target.value)}} placeholder={placeholderInspiration}></textarea>

      {/* [TODO] translateion */}
      <button className={(disableCta) ? styles.disabled : ''} onClick={() => addPostToDB()}>
        <IoIosAddCircleOutline className={styles.icon} size="22" />
        Add entry
      </button>
    </div>
  )
}

export default AddPost;