import styles from './Home.module.css';

import Tree from '../../components/Tree/Tree';
import AddPost from '../AddPost/AddPost';

const Home =  () => {
  return (
    <div className={styles.homePage}>
      <div className={styles.homeWrap}>
        <Tree />
        <AddPost />
      </div>
    </div>
  )
}

export default Home;