import { useState, useEffect, useContext } from "react";
import AuthStoreContext from "../../util/AuthContext";
import StoreContext from "../../util/StoreContext";
import { AuthStoreType, StoreType } from "../../util/types";

import styles from './Profile.module.css';

import { getAuth, signOut } from "firebase/auth";
import { getDatabase, ref, onValue } from "firebase/database";
import { useTranslation } from "react-i18next";

import { differenceInDays } from "../../util/util";

import { IoIosRibbon, IoMdMail, IoIosLogOut, IoIosWarning } from "react-icons/io";

import Modal from "../../components/Modal/Modal";
import flags from "../../util/flags";

import DeleteAccount from "../../components/DeleteAccount/DeleteAccount";

import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

const Profile = () => {
  const { storeTreeType } = useContext(StoreContext) as StoreType;
  const { userId } = useContext(AuthStoreContext) as AuthStoreType;
  const auth = getAuth();

  const { t, i18n } = useTranslation();

  const [totalEntries, setTotalEntries] = useState();
  const [totalDays, setTotalDays] = useState<number>();
  const [totalTrees, setTotalTrees] = useState();
  const [isLoadingSignout, setIsLoadingSignout] = useState(false);
  const [isLanguageSelectorModalOpen, setIsLanguageSelectorModalOpen] = useState(false);
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState(false);

  const db = getDatabase();

  useEffect(() => {
    const totalEntriesRef = ref(db, `users/${userId}/noOfEntries`);
    onValue(totalEntriesRef, (snapshot) => {
      setTotalEntries(snapshot.val() || 0);
    });
    
    const accountCreatedRef = ref(db, `users/${userId}/accountCreationDate`);
    onValue(accountCreatedRef, (snapshot) => {
      const accountCreatedDate = snapshot.val();
      const today = Date.now();
      
      if (accountCreatedDate) {
        // add 1 so that we do not have 0 days in first day
        setTotalDays(differenceInDays(today, accountCreatedDate) + 1);
      }
    });

    const totalTreesRef = ref(db, `users/${userId}/collectionNo`);
    onValue(totalTreesRef, (snapshot) => {
      setTotalTrees(snapshot.val() || 0);
    });
  }, [userId]);

  // [TODO] loading state on button
  const logout = () => {
    signOut(auth).then(() => {
      setIsLoadingSignout(false);
    }).catch((error) => {
      // [TODO] error
      console.log('sign out error');
      console.log(error);
      setIsLoadingSignout(false);
      // An error happened.
      // setIsLoadingSignout(false);
    });
  }

  const showChangeLanguageModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsLanguageSelectorModalOpen(true);
  }

  const showDeleteAccountModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsDeleteAccountModalOpen(true);
  }

  return (
    <div className="subpage-wrap">
      <div className="subpage">
        <h1>{t('profile-title')}</h1>

        <div className={styles.statsContainer}>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <div className={styles.circle}>
                {totalEntries}
              </div>
              <div className={styles.label}>
                {t('profile-total-entries')}
              </div>
            </div>

            <div className={styles.stat}>
              <div className={styles.circle}>
                {totalDays}
              </div>
              <div className={styles.label}>
                {totalDays === 1
                  ?
                    <>
                      {t('profile-total-day')}
                    </>
                  :
                    <>
                      {t('profile-total-days')}
                    </>
                }
              </div>
            </div>

            <div className={styles.stat}>
              <div className={styles.circle}>
                {totalTrees}
              </div>
              <div className={styles.label}>
                {t('profile-total-trees')}
              </div>
            </div>
          </div>

          <div className={styles.treeTypeContainer}>
            <div className={styles.treeTypeCopy}>
              <IoIosRibbon size="22" />
              {t('profile-tree-status')}
            </div>
            <div>
              {storeTreeType === 1 &&
                <>{t('profile-tree-status-1')}</>
              }

              {storeTreeType === 2 &&
                <>{t('profile-tree-status-2')}</>
              }

              {storeTreeType === 3 &&
                <>{t('profile-tree-status-3')}</>
              }
            </div>
          </div>
        </div>

        <div className={styles.optionsWrap}>
          <a
            className={styles.button}
            href={'mailto:proudofapp@gmail.com'}
          >
            <div>{t('profile-btn-send-feedback')}</div>
            <IoMdMail color="#8f8f8f" size="22" />
          </a>

          <a
            className={styles.button}
            href='#'
            onClick={(e) => showChangeLanguageModal(e)}
          >
            <div>{t('profile-btn-change-language')}</div>
            <div className={styles.flag}>
              {flags[i18n.language || 'en']}
            </div>
          </a>

          <a
            className={`${styles.button} ${styles.btnLogout}`}
            href='#'
            onClick={logout}
          >
            <div>{t('profile-btn-logout')}</div>
            <IoIosLogOut size="22" />
          </a>

          <div className={styles.deleteAccountWrap}>
            <a
              className={`${styles.button} ${styles.btnDeleteAccount}`}
              href='#'
              onClick={(e) => showDeleteAccountModal(e)}
            >
              <div>{t('profile-btn-delete-account')}</div>
              <IoIosWarning size="22" />
            </a>
          </div>

          {/* <Pressable
          style={styles.button}
          onPress={changeLanguage}>
            <Text>{t('profile-btn-change-language')}</Text>
            <Text style={{fontSize: 16, marginRight: 6}}>{flags[i18n.language || 'en']}</Text>
        </Pressable> */}

{/* <Pressable
        style={{backgroundColor: '#E9F5FF', borderRadius: 10, padding: 16, justifyContent: 'space-between', flexDirection: 'row'}}
        onPress={() => setIsLoadingSignout(true)}>
          <Text style={{color: '#6BB6EF'}}>
            {t('profile-btn-logout')}
          </Text>
          {isLoadingSignout
            ?
              <ActivityIndicator color="#6BB6EF" />
            :
              <Icon name='log-out-outline' style={{marginRight: 6}} color="#6BB6EF" size={20} />
          }
          
      </Pressable> */}
        </div>
      </div>

      {isDeleteAccountModalOpen &&
        <Modal setIsModalOpen={setIsDeleteAccountModalOpen}>
          <DeleteAccount isModalOpen={setIsDeleteAccountModalOpen} />
        </Modal>
      }
      
      {isLanguageSelectorModalOpen &&
        <Modal setIsModalOpen={setIsLanguageSelectorModalOpen}>
          <LanguageSelector isModalOpen={setIsLanguageSelectorModalOpen} />
        </Modal>
      }
    </div>
  )
}

export default Profile;