import { useState, useEffect, useContext } from "react";
import styles from './Login.module.css';
import { Link } from "react-router-dom";

import app from "../../../firebaseConfig";

import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { useTranslation } from "react-i18next";

import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import Spinner from "../../../components/Spinner/Spinner";

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);

  const [dbErr, setDbErr] = useState(false);
  const [dbErrCode, setDbErrCode] = useState('');
  const [dbErrMsg, setDbErrMsg] = useState('');

  const [signInUser, setSignInUser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const auth = getAuth(app);
  const { t } = useTranslation();

  const signIn = (e: React.SyntheticEvent) => {
    e.preventDefault();

    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    let err = false;

    if (emailReg.test(email.trim()) === false) {
      err = true;
      setEmailErr(true);
    }

    if (password.trim().length <= 0) {
      err = true;
      setPasswordErr(true)
    }

    if (!err) {
      // sign in user in db
      setSignInUser(true);
    }
  }

  useEffect(() => {
    setEmailErr(false);
  }, [email]);

  useEffect(() => {
    setPasswordErr(false);
  }, [password]);

  useEffect(() => {
    if (signInUser) {
      setIsLoading(true);
      signInWithEmailAndPassword(auth, email.trim(), password)
        .then((userCredential) => {
          console.log('success');
          console.log(userCredential.user);
          // Signed up 
          // const user = userCredential.user;
          // setSignInUser(false);
          // setIsLoading(false);
          // navigation.navigate('HomeTab');
        })
        .catch((error) => {
          setDbErr(true);
          setDbErrCode(error.code);
          setDbErrMsg(error.message);

          console.log(error.code);
          // 'auth/invalid-login-credentials' is treated in view
          // any error other error will be shown to user in a popup
          if (error.code !== 'auth/invalid-credential') {
            // const errorMsg = `${error.message}`
            // setGeneralError(errorMsg);
          }

          setIsLoading(false);
          setSignInUser(false);
        });
    }

  }, [signInUser]);

  const togglePassVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className={styles.login}>
      <h2>Login to your account</h2>

      <form className={styles.createAccount} onSubmit={e => signIn(e)}>
        <div className={`${styles.inputWrap} ${(emailErr) ? styles.error : ''}`}>
          <input
            type="text"
            value={email}
            placeholder={t('auth-email-address')}
            onChange={(e) => setEmail(e.target.value)}
            data-testid="input-login-email"
          />

          {emailErr &&
            <p className={styles.errorMsg}>{t('auth-err-invalid-email')}</p>
          }

          {dbErr && dbErrCode === 'auth/invalid-credential' &&
            <p className={styles.errorMsg}>{t('auth-err-invalid-login-details')}</p>
          }
        </div>

        <div className={`${styles.inputWrap} ${(passwordErr) ? styles.error : ''}`}>
          <div className={styles.inputWrapper}>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder={t('auth-password')}
              onChange={(e) => setPassword(e.target.value)}
              data-testid="input-login-password"
            />

            <span className={styles.showPasswordBtn} onClick={togglePassVisibility}>
              {showPassword 
              ?
                <IoIosEye size={22} />
              :
                <IoIosEyeOff size={22} />
              }
            </span>
          </div>

          {passwordErr && password.length === 0 &&
            <p className={styles.errorMsg}>{t('auth-err-blank-password')}</p>
          }
        </div>

        <p className={styles.footerCopy}><Link to="/auth/forgot">{t('auth-signin-forgot-pass-cta')}</Link></p>

        <button
          type="submit"
          className={styles.cta}
          disabled={isLoading ? true : false}
          data-testid="input-btn-login"
        >
          {isLoading &&
            <Spinner />
          }
          {t('auth-signin-main-cta')}
        </button>
      </form>

      {/* [TODO] transalte */}
      <p className={styles.footerCopy}>Don't have an account? <Link to="/auth/signup">Create on for free.</Link></p>
    </div>
  )
}

export default Login;