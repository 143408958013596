import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en";
import ro from "./ro";
import fr from "./fr";
import es from "./es";
import pt from "./pt";

const resources = {
  en: {
    translation: en
  },
  ro: {
    translation: ro
  },
  fr: {
    translation: fr
  },
  es: {
    translation: es
  },
  pt: {
    translation: pt
  }
};


// if the user has saved the language before, we read from local storage to set the language as he chose
// if user did not choose a language, set the language of the device
// fallback to EN language as last resort
const initializeLanguage = async () => {
  let localLanguage = 'en';
  try {
    const value = localStorage.getItem('lang');
    if (value !== null) {
      localLanguage = value;
    }
  } catch (e) {
    // error reading value will fall to default
  }

  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      compatibilityJSON: 'v3',
      resources,
      fallbackLng: 'en',
      lng: localLanguage, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false // react already safes from xss
      }
    });
};

initializeLanguage();

export default i18n;