import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import StoreContext from "../../util/AuthContext";
import { AuthStoreType } from "../../util/types";

const ProtectedRoute = () => {
  const { userId } = useContext(StoreContext) as AuthStoreType; // determine if authorized, from context or however you're doing it
  
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return userId ? <Outlet /> : <Navigate to="/auth" />;
};

export default ProtectedRoute;