import styles from './Post.module.css';
import { useTranslation } from 'react-i18next';

import PostSingle from '../PostSingle/PostSingle';

const Post = (props: any) => {
  const { t } = useTranslation();

  const weekDays: {[key: number]: string} = {
    0: t('sun'),
    1: t('mon'),
    2: t('tue'),
    3: t('wed'),
    4: t('thu'),
    5: t('fri'),
    6: t('sat'),
  };
  
  const months: {[key: number]: string} = {
    1: t('jan'),
    2: t('feb'),
    3: t('mar'),
    4: t('apr'),
    5: t('may'),
    6: t('jun'),
    7: t('jul'),
    8: t('aug'),
    9: t('sep'),
    10: t('oct'),
    11: t('nov'),
    12: t('dec'),
  };

  return (
    <div className={styles.post}>
      {props.subtitle && (
        <h3>
          {/* [TODO] month */}
          {months[props.post.month]}&nbsp;
          {props.post.year}
        </h3>
      )}

      <div className={styles.entry}>
        <div className={styles.dateWrap}>
          <div className={styles.dateBox}>
            <div className={styles.day}>{weekDays[props.post.day]}</div>
            <div className={styles.date}>{props.post.date}</div>
          </div>
          <div className={styles.verticalBar}></div>
        </div>

        <div className={styles.entryBox}>
          
            {props.post.entries.map((item: any) => (
              <PostSingle key={item.entryId} post={item} noOfItems={props.post.entries.length} />
              // <a key={item.entryId} className={styles.entryText} onClick={() => setIsEditable(true)} href="#">
              //   {props.post.entries.length > 1 && "\u2022 "}
              //   {item.entry}

              //   <IoMdCreate className={styles.editIcon} size="14" />
              // </a>
            ))}
          
        </div>
      </div>
    </div>
  )
}

export default Post;