// https://www.webnots.com/copy-paste-country-flag-emoji-symbols/

const flags: {[key: string]: string} = {
  en: '🇬🇧',
  ro: '🇷🇴',
  fr: '🇫🇷',
  de: '🇩🇪',
  pt: '🇵🇹',
  es: '🇪🇸',
  it: '🇮🇹',
  'pt-br': '🇧🇷'
}

export default flags;