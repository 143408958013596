const es = {
  "onb-screen1-title": "Bienvenida a ProudOf",
  "onb-screen1-subtitle": "Nuestra aplicación enfocada al desarrollo personal",
  "onb-screen1-text": "Cultiva tu amor propio agregando entradas y nutriendo tu árbol simbólico de progreso.",

  "onb-screen2-title": "Agregue entradas para hacer crecer tu árbol y crear una colección",
  "onb-screen2-subtitle": "Cada 15 entradas eleva tu árbol a nuevas alturas de logros.",

  "onb-screen3-title": "Personaliza tu árbol",
  "onb-screen3-subtitle": "Personaliza la apariencia de tu árbol con complementos de la tienda.",

  "onb-skip": "Saltar",

  // Auth
  "auth-create-account-title": "Crea una cuenta nueva",
  "auth-email-address": "Correo eléctronico",
  "auth-password": "Contraseña",
  "auth-password-repeat": "Repita la contraseña",
  
  // Auth err
  "auth-err-invalid-email": "Por favor introduzca una dirección de correo electrónico válida",
  "auth-err-email-in-use": "Dirección de correo electrónico ya está en uso",
  "auth-err-blank-password": "Porfavor ingrese una contraseña",
  "auth-err-password-6-char": "La contraseña debe tener al menos 6 caracteres",
  "auth-err-passwords-not-same": "Las contraseñas no son similares",
  "auth-err-invalid-login-details": "Credenciales de acceso invalidos",
  
  "auth-create-account-main-cta": "Crea una cuenta gratis",
  "auth-already-have-account": "¿Ya tienes una cuenta?",
  "auth-sign-in-cta": "Iniciar sesión",
  
  "auth-terms-part1": "Al registrarte estás indicando que has leído y aceptado las",
  "auth-terms": "Términos y condiciones",
  "auth-terms-and": "y",
  "auth-privacy": "Política de privacidad",
  
  "auth-signin-title": "Inicie sesión en tu cuenta",
  "auth-signin-forgot-pass-cta": "¿Has olvidado tu contraseña?",
  "auth-signin-main-cta": "Acceso",
  "auth-signin-create-cta": "¿No tienes una cuenta? <1>Regístrate</1> gratis",
  
  "auth-forgot-title": "Has olvidado tu contraseña",
  "auth-forgot-main-cta": "Restablecer la contraseña",
  "auth-forgot-already-account": "¿Ya tienes una cuenta?",
  "auth-forgot-sign-in": "Iniciar sesión",

  "home-main-cta": "Añadir una entrada",
  "home-secondary-cta": "Entradas pasadas",
  
  "notification-daily-checkin": "Has recibido {{dailyCheckinHeartsAward}} puntos por el check-in diario.",
  
  "add-post-title": "Hoy estoy orgulloso de:",

  "mon": "LUN",
  "tue": "MAR",
  "wed": "MIÉ",
  "thu": "JUE",
  "fri": "VIE",
  "sat": "SÁB",
  "sun": "DOM",

  "jan": "Enero",
  "feb": "Febrero",
  "mar": "Marzo",
  "apr": "Abril",
  "may": "Mayo",
  "jun": "Junio",
  "jul": "Julio",
  "aug": "Agosto",
  "sep": "Septiembre",
  "oct": "Octubre",
  "nov": "Noviembre",
  "dec": "Diciembre",

  "loading": "Cargando...",
  
  "posts-no-entries-title": "Aún no hay entradas.",
  "posts-no-entries-cta": "Añade tu primera entrada",
  
  "edit-entry-title": "Editar entrada:",
  "edit-entry-delete-cta": "Eliminar la entrada",
  "edit-entry-delete-modal-title": "¿Estás seguro de que deseas eliminar esta entrada?",
  "edit-entry-delete-modal-yes-cta": "Sí",
  "edit-entry-delete-modal-no-cta": "No",
  
  "tree-collection-title": "Tu colección de árboles",
  "tree-collection-empty-msg": "Tu colección de árboles está actualmente vacía. Los árboles que alcancen el máximo crecimiento se guardarán automáticamente en tu colección.",

  "profile-title": "Tus estadísticas",
  "profile-total-entries": "Entradas totales",
  "profile-total-day": "Día",
  "profile-total-days": "Días",
  "profile-total-trees": "Árboles recolectados",
  "profile-tree-status": "El estado actual de tu árbol",
  "profile-tree-status-1": "Joven",
  "profile-tree-status-2": "Adulto",
  "profile-tree-status-3": "Secular",
  "profile-btn-send-feedback": "Enviar comentarios",
  "profile-btn-how-it-works": "Como funciona",
  "profile-btn-logout": "Cerrar sesión",
  "profile-btn-delete-account": "Borrar cuenta",
  "profile-btn-change-language": "Cambiar idioma",
  "profile-btn-shop": "Tienda",

  // Delete account
  "modal-delete-account-title": "¿Estás seguro de que quieres eliminar tu cuenta?",
  "modal-delete-account-subtitle": "Su cuenta y todos sus datos serán eliminados.",
  "modal-delete-account-text": "Ingrese su contraseña para eliminar su cuenta.",
  "password": "Contraseña",
  "modal-delete-account-invalid-credentials": "Credenciales de acceso invalidos",
  "modal-delete-account-main-cta": "Borrar cuenta",
  "modal-delete-account-cancel-cta": "No",
  
  "shop-tab-title": "Tienda",
  "customize-tab-title": "Personalizar",
  
  "shop-page-title": "Aves",
  
  "customize-page-save-cta": "Guardar cambios",
  "customize-page-title": "Personaliza tu árbol",
  "customize-page-no-addon-cta": "Conseguir accesorio",

  "offline-notification": "Estás desconectado, es posible que algunas de las funciones no se guarden sin una conexión a Internet activa",

  "general-error": "Algo salió mal. Por favor, inténtelo de nuevo más tarde.",
  "general-notification-auth-reset-link-sent": "Se ha enviado un enlace de reinicio a su dirección de correo electrónico.",

  "profile-no-account-text" : "Tus datos no están actualmente anadidos a ninguna cuenta y solo son accesibles en este dispositivo. En caso de desinstalar la aplicación o perder el dispositivo, tus datos serán irrecuperables. Crea una cuenta gratuita para guardar tus datos y acceder a ellos en otros dispositivos.",

  "auth-slogan": "Abraza tu historia: Aplicación de diario para hacer crecer tu confianza, fomentar tu evolución y el bienestar.",
  "auth-continue-without-account": "Continuar sin crear una cuenta",
  "auth-continue-without-account-note": "Ten en cuenta que continuar sin una cuenta resultará en la pérdida de tus datos al eliminar la aplicación. Puedes optar por crear una cuenta más tarde desde la página de perfil.",

  "inspiration-1": "p.ej. Me relajé con una meditación de 15 minutos hoy.",
  "inspiration-2": "p.ej. Me aseguré de no saltarme el desayuno esta mañana.",
  "inspiration-3": "p.ej. Hice cardio en el gimnasio, corriendo 5 km en la cinta.",
  "inspiration-4": "p.ej. Pasé una hora agradable en el parque con mi perro.",
  "inspiration-5": "p.ej. En lugar de optar por la comida rápida, me tomé el tiempo de preparar una cena deliciosa.",
  "inspiration-6": "p.ej. Me sumergí en mi nuevo libro, leyendo 30 páginas.",
  "inspiration-7": "p.ej. Disfruté de una conversación telefónica con un amigo.",
}

export default es;