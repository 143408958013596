import moment from "moment";

export const isSameDay = (timestamp1, timestamp2) => {
  const firstTimestamp = new Date(timestamp1).setHours(0, 0, 0, 0);
  const secondTimestamp = new Date(timestamp2).setHours(0, 0, 0, 0);

  if(firstTimestamp === secondTimestamp){
    return true;
  }

  return false;
}

// reset timestamps to 0 seconds, 0 minutes, 0 ms because momentjs compares by hours so, difference in timestamps could be 22 hours which results to 0 days difference, but we are interested if it's next day, so we shoudl also count it as a full day
export const differenceInDays = (timestamp1, timestamp2) => {
  const a = moment(new Date(timestamp1).setHours(0, 0, 0, 0));
  const b = moment(new Date(timestamp2).setHours(0, 0, 0, 0));

  return a.diff(b, 'days')
}

export default isSameDay;