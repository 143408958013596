const pt = {
  "onb-screen1-title": "Bem-vindo ao ProudOf",
  "onb-screen1-subtitle": "Nosso aplicativo focado no desenvolvimento pessoal",
  "onb-screen1-text": "Cultive seu amor próprio adicionando entradas e nutrindo sua árvore simbólica de progresso.",

  "onb-screen2-title": "Adicione entradas para aumentar sua árvore e construir uma coleção",
  "onb-screen2-subtitle": "Cada 15 entradas elevam sua árvore a novos patamares de conquistas.",

  "onb-screen3-title": "Personalize sua árvore",
  "onb-screen3-subtitle": "Personalize a aparência da sua árvore com complementos da loja.",

  "onb-skip": "Pular",

  // Auth
  "auth-create-account-title": "Criar uma conta gratuita",
  "auth-email-address": "Endereço de email",
  "auth-password": "Senha",
  "auth-password-repeat": "Repita a senha",
  
  // Auth err
  "auth-err-invalid-email": "Por favor insira um endereço de e-mail válido",
  "auth-err-email-in-use": "Endereço de email já está em uso",
  "auth-err-blank-password": "Por favor insira uma senha",
  "auth-err-password-6-char": "A senha deve ter pelo menos 6 caracteres",
  "auth-err-passwords-not-same": "Senhas nao sao as mesmas",
  "auth-err-invalid-login-details": "Credenciais de login inválidas",
  
  "auth-create-account-main-cta": "Crie uma conta gratuita",
  "auth-already-have-account": "Já tem uma conta?",
  "auth-sign-in-cta": "Entrar",
  
  "auth-terms-part1": "Ao se inscrever, você indica que leu e concordou com os",
  "auth-terms": "Termos e Condições",
  "auth-terms-and": "e",
  "auth-privacy": "Política de Privacidade",
  
  "auth-signin-title": "Faça login em sua conta",
  "auth-signin-forgot-pass-cta": "Esqueceu sua senha?",
  "auth-signin-main-cta": "Conecte-se",
  "auth-signin-create-cta": "Não tem uma conta? <1>Inscreva-se</1> gratuitamente",
  
  "auth-forgot-title": "Esqueceu sua senha",
  "auth-forgot-main-cta": "Redefinir senha",
  "auth-forgot-already-account": "já tem uma conta?",
  "auth-forgot-sign-in": "Entrar",

  "home-main-cta": "Adicionar uma entrada",
  "home-secondary-cta": "Entradas anteriores",
  
  "notification-daily-checkin": "Você recebeu {{dailyCheckinHeartsAward}} pontos pelo check-in diário.",
  
  "add-post-title": "Hoje tenho orgulho de:",

  "mon": "SEG",
  "tue": "TER",
  "wed": "QUA",
  "thu": "QUI",
  "fri": "SEX",
  "sat": "SAB",
  "sun": "DOM",

  "jan": "Janeiro",
  "feb": "Fevereiro",
  "mar": "Março",
  "apr": "Abril",
  "may": "Maio",
  "jun": "Junho",
  "jul": "Julho",
  "aug": "Agosto",
  "sep": "Setembro",
  "oct": "Outubro",
  "nov": "Novembro",
  "dec": "Dezembro",

  "loading": "Carregando...",
  
  "posts-no-entries-title": "Ainda não há entradas.",
  "posts-no-entries-cta": "Adicione sua primeira entrada",
  
  "edit-entry-title": "Editar entrada:",
  "edit-entry-delete-cta": "Excluir entrada",
  "edit-entry-delete-modal-title": "Tem certeza de que deseja excluir esta entrada?",
  "edit-entry-delete-modal-yes-cta": "Sim",
  "edit-entry-delete-modal-no-cta": "Não",
  
  "tree-collection-title": "Sua coleção de árvores",
  "tree-collection-empty-msg": "Sua coleção de árvores está vazia no momento. As árvores que atingirem o crescimento máximo serão salvas automaticamente em sua coleção.",

  "profile-title": "Suas estatísticas",
  "profile-total-entries": "Entradas totais",
  "profile-total-day": "Dia",
  "profile-total-days": "Dias",
  "profile-total-trees": "Árvores coletadas",
  "profile-tree-status": "O status atual da sua árvore",
  "profile-tree-status-1": "Jovem",
  "profile-tree-status-2": "Adulto",
  "profile-tree-status-3": "Secular",
  "profile-btn-send-feedback": "Enviar comentários",
  "profile-btn-how-it-works": "Como funciona",
  "profile-btn-logout": "Sair",
  "profile-btn-delete-account": "Deletar conta",
  "profile-btn-change-language": "Mudar Idioma",
  "profile-btn-shop": "Comprar",

  // Delete account
  "modal-delete-account-title": "Tem certeza de que deseja excluir sua conta?",
  "modal-delete-account-subtitle": "Sua conta e todos os seus dados serão excluídos.",
  "modal-delete-account-text": "Digite sua senha para excluir sua conta.",
  "password": "Senha",
  "modal-delete-account-invalid-credentials": "Credenciais de login inválidas",
  "modal-delete-account-main-cta": "Deletar conta",
  "modal-delete-account-cancel-cta": "Não",
  
  "shop-tab-title": "Comprar",
  "customize-tab-title": "Customizar",
  
  "shop-page-title": "Pássaros",
  
  "customize-page-save-cta": "Salvar alterações",
  "customize-page-title": "Personalize sua árvore",
  "customize-page-no-addon-cta": "Obtenha acessório",

  "offline-notification": "Você está off-line, alguns dos recursos podem não ser salvos sem uma conexão ativa com a Internet",

  "general-error": "Algo deu errado. Por favor, tente novamente mais tarde.",
  "general-notification-auth-reset-link-sent": "Um link de redefinição foi enviado para seu endereço de e-mail.",

  "profile-no-account-text" : "Seus dados não estão atualmente vinculados a nenhuma conta e são exclusivamente acessíveis neste dispositivo. Em caso de desinstalação do aplicativo ou perda do dispositivo, seus dados serão irreversíveis. Crie uma conta gratuita para salvar seus dados e acessá-los em outros dispositivos.",

  "auth-slogan": "Abrace Sua História: Aplicativo de Diário para Impulsionar a Confiança com Suavidade, Fomentar o Crescimento e o Bem-Estar Consciente.",
  "auth-continue-without-account": "Prossiga sem criar uma conta",
  "auth-continue-without-account-note": "Por favor, saiba que continuar sem uma conta resultará na perda de seus dados ao excluir o aplicativo. Você pode optar por criar uma conta posteriormente na página de perfil.",

  "inspiration-1": "Eu relaxei com uma meditação de 15 minutos hoje.",
  "inspiration-2": "Garanti não pular o café da manhã hoje de manhã.",
  "inspiration-3": "Fiz cardio na academia, correndo 5 km na esteira.",
  "inspiration-4": "Passei uma hora agradável no parque com meu cachorro.",
  "inspiration-5": "Em vez de optar por fast food, dediquei tempo para preparar um jantar delicioso.",
  "inspiration-6": "Me envolvi no meu novo livro, lendo 30 páginas.",
  "inspiration-7": "Desfrutei de uma conversa telefônica com um amigo.",
}

export default pt;