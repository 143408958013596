import { QuoteTypeConfig } from '../util/types';

const quotes: QuoteTypeConfig = {
  en: {
    1: {
      quote: 'Becoming proud of yourself is the best gift you can give yourself.',
      author: 'Margo Vader'
    },
    2: {
      quote: 'Proud of you for staying committed to your dreams even when things got tough.',
      author: 'Mel Robbins'
    },
    3: {
      quote: 'You deserve to feel proud of your progress, no matter how small.',
      author: 'Shawn Achor'
    },
    4: {
      quote: 'Being proud of yourself is not arrogant; it is a sign of self-respect.',
      author: 'Alice Walker'
    },
    5: {
      quote: 'When you take ownership of your accomplishments, you can be proud without arrogance.',
      author: 'Simon Sinek'
    },
    6: {
      quote: 'Proud moments are not just for big accomplishments but also for small victories along the way.',
      author: 'Jay Shetty'
    }
  }
}

export default quotes;