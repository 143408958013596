import { useState, useEffect } from "react";
import styles from './CreateAccount.module.css';
import { Link } from "react-router-dom";

import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { getDatabase, ref, set } from "firebase/database";

import { useTranslation } from 'react-i18next';

import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import Spinner from "../../../components/Spinner/Spinner";

const CreateAccount = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [repeatPassword, setRepeatPassword] = useState<string>('');

  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [repeatPasswordErr, setRepeatPasswordErr] = useState(false);

  const [dbErr, setDbErr] = useState(false);
  const [dbErrCode, setDbErrCode] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [createUser, setCreateUser] = useState(false);

  const auth = getAuth();
  const { t } = useTranslation();

  const createAccount = (e: React.SyntheticEvent) => {
    e.preventDefault();
    
    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    let err = false;

    if (emailReg.test(email.trim()) === false) {
      err = true;
      setEmailErr(true);
    }

    if (password.trim().length <= 5) {
      err = true;
      setPasswordErr(true)
    }

    if (password !== repeatPassword) {
      err = true;
      setRepeatPasswordErr(true)
    }

    if (!err) {
      // create account in db
      setCreateUser(true);
    }
  }

  useEffect(() => {
    setEmailErr(false);
  }, [email]);

  useEffect(() => {
    setPasswordErr(false);
    setRepeatPasswordErr(false);
  }, [password, repeatPassword]);

  useEffect(() => {
    if (createUser) {
      setIsLoading(true);
      console.log('create user')

      const db = getDatabase();
      const timestamp = Date.now();

      createUserWithEmailAndPassword(auth, email.trim(), password)
        .then((userCredential) => {
          // Signed up 
          // const user = userCredential.user;
          setCreateUser(false);
          setIsLoading(false);

          // create the user table for this specific user
          set(ref(db, 'users/' + userCredential.user.uid), {
            hearts: 0,
            accountCreationDate: timestamp,
            treeLevel: 0
          });
        })
        .catch((error) => {
          // [TODO] errors
          // setDbErr(true);
          // setDbErrCode(error.code);
          // setDbErrMsg(error.message);
          console.warn(error.message)

          setIsLoading(false);
          setCreateUser(false);
        });
    }

  }, [createUser]);

  // [TODO] show pass
  const togglePassVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className={styles.createAccount}>
      <h2>{t('auth-create-account-title')}</h2>

      <form className={styles.createAccount} onSubmit={e => createAccount(e)}>
        <div className={`${styles.inputWrap} ${(emailErr) ? styles.error : ''}`}>
          <input
            type="text"
            value={email}
            placeholder={t('auth-email-address')}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailErr &&
            <p className={styles.errorMsg}>{t('auth-err-invalid-email')}</p>
          }

          {dbErr && dbErrCode === 'auth/email-already-in-use' &&
            <p className={styles.errorMsg}>{t('auth-err-email-in-use')}</p>
          }
        </div>

        <div className={`${styles.inputWrap} ${(passwordErr) ? styles.error : ''}`}>
          <div className={styles.inputWrapper}>
            <input
              type={showPassword ? 'text' : 'password'}
              value={password}
              placeholder={t('auth-password')}
              onChange={(e) => setPassword(e.target.value)}
            />

            <span className={styles.showPasswordBtn} onClick={togglePassVisibility}>
              {showPassword 
              ?
                <IoIosEye size={22} />
              :
                <IoIosEyeOff size={22} />
              }
            </span>
          </div>

          {passwordErr && password.length === 0 &&
            <p className={styles.errorMsg}>{t('auth-err-blank-password')}</p>
          }

          {passwordErr && password.length > 0 && password.length <= 5 &&
            <p className={styles.errorMsg}>{t('auth-err-password-6-char')}</p>
          }
        </div>
        
        <div className={`${styles.inputWrap} ${(repeatPasswordErr) ? styles.error : ''}`}>
          <div className={styles.inputWrapper}>
            <input
              type={showPassword ? 'text' : 'password'}
              value={repeatPassword}
              placeholder={t('auth-password-repeat')}
              onChange={(e) => setRepeatPassword(e.target.value)}
            />

            <span className={styles.showPasswordBtn} onClick={togglePassVisibility}>
              {showPassword 
              ?
                <IoIosEye size={22} />
              :
                <IoIosEyeOff size={22} />
              }
            </span>
          </div>
          
          {repeatPasswordErr &&
            <p className={styles.errorMsg}>{t('auth-err-passwords-not-same')}</p>
          }
        </div>
        
        {/* auth-create-account-main-cta */}
        <button
          className={styles.cta}
          type="submit"
          disabled={isLoading ? true : false}
        >
          {isLoading &&
            <Spinner />
          }
          {t('auth-create-account-main-cta')}
        </button> 
      </form>

      <p className={styles.footerCopy}>{t('auth-already-have-account')}  <Link to="/auth/login" data-testid="btn-go-to-login">{t('auth-sign-in-cta')}</Link></p>

      <p className={styles.footerCopy}>
        {t('auth-terms-part1')}&nbsp;
        <a href="https://sites.google.com/view/proudof-terms-and-conditions" target="_blank" rel="noreferrer">
          {t('auth-terms')}
        </a>
        &nbsp;{t('auth-terms-and')}&nbsp;
        <a href="https://sites.google.com/view/proudof-privacy-policy" target="_blank" rel="noreferrer">
          {t('auth-privacy')}
        </a>
      </p>
    </div>
  )
}

export default CreateAccount;