import styles from './TreeCollectible.module.css';
import addonImages from "../../util/addons";

const collectables: { [key: string]: string } = {
  bird1: require('../../assets/tree-collectible/tree-collectible-color1.png'),
  bird2: require('../../assets/tree-collectible/tree-collectible-color2.png'),
  bird3: require('../../assets/tree-collectible/tree-collectible-color3.png'),
  bird4: require('../../assets/tree-collectible/tree-collectible-color4.png'),
  bird5: require('../../assets/tree-collectible/tree-collectible-color5.png'),
  1000: require('../../assets/tree-collectible/tree-collectible.png'),
};

const TreeCollectible = ({ addon }:{ addon: string | number }) => {
   
  return (
    <div className={styles.circle}>
      {addon && addon !== 1000 &&  
        <div className={styles.addonContainer}>  
          <img src={addonImages[addon]} width="100%" height="100%" />
        </div>
      }
      <img src={collectables[addon || 'default']} width="100%" height="100%" />
    </div>
  )
}

export default TreeCollectible;