import { createContext, useState, useEffect, useContext } from 'react';
import { StoreType, AuthStoreType } from './types';

import {
  getDatabase,
  ref,
  onValue,
  query
} from "firebase/database";

import AuthContext from './AuthContext';

const StoreContext = createContext<StoreType | null>(null);

export const StoreProvider = ({ children }: {children: any}) => {
  const [treeLevelFromDB, setTreeLevelFromDB] = useState(null);
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [postAddedFromApp, setPostAddedFromApp] = useState(false);
  const [storeTreeType, setStoreTreeType] = useState(1);

  const { userId } = useContext(AuthContext) as AuthStoreType;

  const storeValue = {
    treeLevelFromDB,
    selectedAddon,
    postAddedFromApp,
    setPostAddedFromApp,
    storeTreeType,
    setStoreTreeType
  };

  const db = getDatabase();

  useEffect(() => {
    // get tree level from db
    const treeLevelRef = query(
      ref(db, `users/${userId}/treeLevel`)
    );
    onValue(treeLevelRef, (snapshot) => {
      const data = snapshot.val();
      setTreeLevelFromDB(data);
    });

    // get selected addon
    const selectedAddonRef = ref(db, `users/${userId}/selectedAddon`);
    onValue(selectedAddonRef, (snapshot) => {
      setSelectedAddon(snapshot.val());
    });

  }, [userId, db]);

  return (
    <StoreContext.Provider value={storeValue}>
      {children}
    </StoreContext.Provider>
  );
}

export default StoreContext;