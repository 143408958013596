import { createContext, useState, useEffect } from 'react';
import { UserType, AuthStoreType } from './types';

import { getAuth, onAuthStateChanged } from "firebase/auth";

const AuthContext = createContext<AuthStoreType | null>(null);

export const AuthProvider = ({ children }: {children: any}) => {
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState<UserType>(null);

  const storeValue = {
    userId
  };

  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setLoading(false);
        // User is signed in
        setUserId(user.uid);
      } else {
        setLoading(false);
        // User is signed out
        // [TODO] hearts
        // setHearts(null);
        setUserId(null);
      }
    });
    return unsubscribe;
  }, [auth]);

  // [TODO] loading component
  if (loading) {
    return (<div>Loading</div>)
  }

  return (
    <AuthContext.Provider value={storeValue}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export default AuthContext;