import { Link, NavLink, Outlet } from 'react-router-dom';

import logo from '../../assets/logo.png';
import styles from './Dashboard.module.css'

import { useTranslation } from 'react-i18next';

const Dashboard = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.app}>
      <aside>
        <div>
          <Link to="/">
            <img src={logo} alt="ProudOf logo" title="ProudOf" className={styles.logo} />
          </Link>

          <nav>
            <menu>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive}) =>
                    isActive ? styles.active : ""
                  }
                >
                  {/* [TODO] Translate all */}
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/entries"
                  className={({ isActive}) =>
                    isActive ? styles.active : ""
                  }
                >
                  Entries
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/awards"
                  className={({ isActive}) =>
                    isActive ? styles.active : ""
                  }
                >
                  Tree Collection
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/shop"
                  className={({ isActive}) =>
                    isActive ? styles.active : ""
                  }
                >
                  Shop
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to="/profile"
                  className={({ isActive}) =>
                    isActive ? styles.active : ""
                  }
                >
                  Profile
                </NavLink>
              </li>
            </menu>
          </nav>
        </div>
      </aside>
      <main>
        <Outlet />
      </main>
    </div>
  )
}

export default Dashboard;