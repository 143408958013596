const en = {
  "onb-screen1-title": "Welcome to ProudOf",
  "onb-screen1-subtitle": "Our growth-focused app",
  "onb-screen1-text": "Cultivate your self-love by adding entries and nurturing your symbolic tree of progress.",

  "onb-screen2-title": "Add entries to grow your tree and build a collection",
  "onb-screen2-subtitle": "Every 15 entries elevate your tree to new heights of achievement.",

  "onb-screen3-title": "Customize your tree",
  "onb-screen3-subtitle": "Personalize your tree's appearance with addons from the shop.",

  "onb-skip": "Skip",

  // Auth
  "auth-create-account-title": "Create a free account",
  "auth-email-address": "Email address",
  "auth-password": "Password",
  "auth-password-repeat": "Repeat password",
  
  // Auth err
  "auth-err-invalid-email": "Please enter a valid email address",
  "auth-err-email-in-use": "Email address already in use",
  "auth-err-blank-password": "Please enter a password",
  "auth-err-password-6-char": "Password should be at least 6 characters",
  "auth-err-passwords-not-same": "Passwords are not the same",
  "auth-err-invalid-login-details": "Invalid login credentials",
  
  "auth-create-account-main-cta": "Create free account",
  "auth-already-have-account": "Already have an account?",
  "auth-sign-in-cta": "Sign in",
  
  "auth-terms-part1": "By signing up, you are indicating that you have read and agreed to the",
  "auth-terms": "Terms of Service",
  "auth-terms-and": "and",
  "auth-privacy": "Privacy Policy",
  
  "auth-signin-title": "Login into your account",
  "auth-signin-forgot-pass-cta": "Forgot password?",
  "auth-signin-main-cta": "Login",
  "auth-signin-create-cta": "Don't have an account? <1>Sign up</1> for free",
  
  "auth-forgot-title": "Forgot password",
  "auth-forgot-main-cta": "Send reset email",
  "auth-forgot-already-account": "Already have an account?",
  "auth-forgot-sign-in": "Sign in",

  "home-main-cta": "Add an entry",
  "home-secondary-cta": "Past entries",
  
  "notification-daily-checkin": "You've received {{dailyCheckinHeartsAward}} points for daily check in.",
  
  "add-post-title": "Today I am proud of:",

  "mon": "MON",
  "tue": "TUE",
  "wed": "WED",
  "thu": "THU",
  "fri": "FRI",
  "sat": "SAT",
  "sun": "SUN",

  "jan": "January",
  "feb": "February",
  "mar": "March",
  "apr": "April",
  "may": "May",
  "jun": "June",
  "jul": "July",
  "aug": "August",
  "sep": "September",
  "oct": "October",
  "nov": "November",
  "dec": "December",

  "loading": "Loading...",
  
  "posts-no-entries-title": "There are no entries yet.",
  "posts-no-entries-cta": "Add your first entry",
  
  "edit-entry-title": "Edit entry:",
  "edit-entry-delete-cta": "Delete entry",
  "edit-entry-delete-modal-title": "Are you sure you want to delete this entry?",
  "edit-entry-delete-modal-yes-cta": "Yes",
  "edit-entry-delete-modal-no-cta": "No",
  
  "tree-collection-title": "Your tree collection",
  "tree-collection-empty-msg": "Your tree collection is currently empty. Trees reaching maximum growth will be automatically saved to your collection.",

  "profile-title": "Your stats",
  "profile-total-entries": "Total Entries",
  "profile-total-day": "Day",
  "profile-total-days": "Days",
  "profile-total-trees": "Collected trees",
  "profile-tree-status": "Your current tree status",
  "profile-tree-status-1": "Young",
  "profile-tree-status-2": "Adult",
  "profile-tree-status-3": "Secular",
  "profile-btn-send-feedback": "Send Feedback",
  "profile-btn-how-it-works": "How does it work",
  "profile-btn-logout": "Logout",
  "profile-btn-delete-account": "Delete Account",
  "profile-btn-change-language": "Change Language",
  "profile-btn-shop": "Shop",

  // Delete account
  "modal-delete-account-title": "Are you sure you want to delete your account?",
  "modal-delete-account-subtitle": "Your account and all of your data will be deleted.",
  "modal-delete-account-text": "Enter your password to delete your account.",
  "password": "Password",
  "modal-delete-account-invalid-credentials": "Invalid login credentials",
  "modal-delete-account-main-cta": "Delete account",
  "modal-delete-account-cancel-cta": "No",
  
  "shop-tab-title": "Shop",
  "customize-tab-title": "Customize",
  
  "shop-page-title": "Birds",
  
  "customize-page-save-cta": "Save changes",
  "customize-page-title": "Customize your tree",
  "customize-page-no-addon-cta": "Get addon",
  
  "offline-notification": "You've are offline, some of the features may not be saved without an active internet connection",

  "general-error": "Something went wrong. Please try again later.",
  "general-notification-auth-reset-link-sent": "A reset link was sent to your email.",

  "profile-no-account-text" : "Your data is presently unassociated with any account and is exclusively accessible on this device. In the event of app uninstallation or device loss, your data will be irretrievable. Create a free account so that you can save your data and access it on other devices.",

  "auth-slogan": "Embrace Your Story: Journal App for Gentle Confidence Boosts, Growth, and Mindful Wellness.",
  "auth-continue-without-account": "Proceed without creating an account",
  "auth-continue-without-account-note": "Please be advised that continuing without an account will result in the loss of your data upon app deletion. You may opt to create an account subsequently through the Profile page.",

  "inspiration-1": "e.g. I did a quick 15-minute meditation today.",
  "inspiration-2": "e.g. Made sure to grab breakfast this morning.",
  "inspiration-3": "e.g. Did some cardio at the gym, ran 5 km on the treadmill.",
  "inspiration-4": "e.g. Hung out at the park with my dog for an hour – it was nice.",
  "inspiration-5": "e.g. Skipped fast food and cooked up a tasty dinner instead.",
  "inspiration-6": "e.g. Got into my new book, read a good 30 pages.",
  "inspiration-7": "e.g. Had a chat with a friend over the phone – it was nice.",
}

export default en;