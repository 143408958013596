import './App.css';

import Auth from './features/Auth/Auth';
import Dashboard from './features/Dashboard/Dashboard';
import Home from './features/Home/Home';
// import Shop from './features/Shop/Shop';
import Entries from './features/Entries/Entries';
import Profile from './features/Profile/Profile';
import Awards from './features/Awards/Awards';

import CreateAccount from './features/Auth/CreateAccount/CreateAccount';
import Login from './features/Auth/Login/Login';
import Forgot from './features/Auth/Forgot/Forgot';

import ProtectedRoute from './features/ProtectedRoute/ProtectedRoute';

// import { authenticateFirebase } from './firebaseConfig';

import { AuthProvider } from './util/AuthContext';
import { StoreProvider } from './util/StoreContext';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from 'react-router-dom';

const App = () => {
  // authenticateFirebase();

  return (
    <AuthProvider>
      <StoreProvider>
        <Router>
          <Routes>
            <Route  path="/" element={<ProtectedRoute />}>
              <Route path="/" element={<Dashboard />}>
                <Route index path="/" element={<Home />} />
                <Route path="/entries" element={<Entries />} />
                <Route path="/awards" element={<Awards />} />
                {/* <Route path="/shop" element={<Shop />} /> */}
                <Route path="/profile" element={<Profile />} />
              </Route>
            </Route>
            <Route path="/auth" element={<Auth />}>
              <Route index element={<CreateAccount />} />
              <Route path="/auth/signup" element={<CreateAccount />} />
              <Route path="/auth/login" element={<Login />} />
              <Route path="/auth/forgot" element={<Forgot />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />

            {/* <Route path="/posts" element={<Posts />}>
              <Route index element={<PostLists />} />
              <Route path=":slug" element={<Post />} />
            </Route> */}
          </Routes>
        </Router>
      </StoreProvider>
    </AuthProvider>
  )
}

export default App;