const treeLevelStates: {[key: number]: number} = {
  0: 0,
  1: 6,
  2: 12,
  3: 18,
  4: 24,
  5: 30,
  6: 36,
  7: 42,
  8: 48,
  9: 54,
  10: 60,
  11: 66,
  12: 72,
  13: 80,
  14: 86,
  15: 100,
  16: 6,
  17: 12,
  18: 18,
  19: 24,
  20: 30,
  21: 36,
  22: 42,
  23: 48,
  24: 54,
  25: 60,
  26: 66,
  27: 72,
  28: 80,
  29: 86,
  30: 100,
  31: 6,
  32: 12,
  33: 18,
  34: 24,
  35: 30,
  36: 36,
  37: 42,
  38: 48,
  39: 54,
  40: 60,
  41: 66,
  42: 72,
  43: 80,
  44: 86,
  45: 100
};

export default treeLevelStates;