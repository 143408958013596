import { useState, useEffect, useRef } from "react";

import styles from './PostSingle.module.css';
import { IoMdCreate } from "react-icons/io";


const PostSingle = ({post, noOfItems} : {post: any, noOfItems: number}) => {
  const [isEditable, setIsEditable] = useState(false);
  const [editableVal, setEditableVal] = useState('');

  const textareaRef = useRef<HTMLDivElement | null>(null);
  let count = 0;

  const handleClickOutside = (e: any) => {
    if (count === 1) {
      count++;
      return;
    }
    if (textareaRef.current && !textareaRef.current.contains(e.target)) {
      setIsEditable(false);
    }
  }

  useEffect(() => {
    if (isEditable) {
      count++;
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [isEditable])

  const editPost = (e: React.SyntheticEvent, entry: string) => {
    e.preventDefault();
    
    setIsEditable(true);
    setEditableVal(entry);
  }

  return (
    <div className={styles.singlePost}>
      {isEditable 
      ?
      <div ref={textareaRef} className={styles.editPostWrapper}>
        <textarea value={editableVal} onChange={(e) => setEditableVal(e.target.value)} />
        <div>
          <button onClick={() => setIsEditable(false)}>Save</button>
          <button onClick={() => setIsEditable(false)} className={styles.secondaryBtn}>Cancel</button>
        </div>
      </div>
      :
      <a className={styles.entryText} onClick={(e) => editPost(e, post.entry)} href="#">
        {noOfItems > 1 && "\u2022 "}
        {post.entry}

        <IoMdCreate className={styles.editIcon} size="14" />
      </a>
      }
    </div>
  )
}

export default PostSingle;