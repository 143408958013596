import { useTranslation } from "react-i18next";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
      {t('loading')}
    </div>
  );
}

export default Loading;