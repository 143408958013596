import { useTranslation } from "react-i18next";

import styles from './LanguageSelector.module.css';
import flags from "../../util/flags";

const LanguageSelector = ({isModalOpen}: {isModalOpen: Function}) => {

  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    // [TODO] change language is too fast, add a loading and a fake 500ms timeout
    localStorage.setItem('lang', lang);
    i18n.changeLanguage(lang);
    isModalOpen(false);
  }

  return (
    <div className={styles.languageSelector}>
      <button className={`${styles.button} ${i18n.language === 'en' ? styles.selected: ''}`} onClick={() => {changeLanguage('en')}}>
        <span className={styles.flag}>{flags['en']}</span> English
      </button>

      <button className={`${styles.button} ${i18n.language === 'ro' ? styles.selected: ''}`} onClick={() => {changeLanguage('ro')}}>
        <span className={styles.flag}>{flags['ro']}</span> Romana
      </button>

      <button className={`${styles.button} ${i18n.language === 'fr' ? styles.selected: ''}`} onClick={() => {changeLanguage('fr')}}>
        <span className={styles.flag}>{flags['fr']}</span> Français
      </button>

      <button className={`${styles.button} ${i18n.language === 'es' ? styles.selected: ''}`} onClick={() => {changeLanguage('es')}}>
        <span className={styles.flag}>{flags['es']}</span> Español
      </button>

      <button className={`${styles.button} ${i18n.language === 'pt' ? styles.selected: ''}`} onClick={() => {changeLanguage('pt')}}>
        <span className={styles.flag}>{flags['pt']}</span> Português
      </button>
    </div>
  );
}

export default LanguageSelector;