import { useState, useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import StoreContext from '../../util/AuthContext';
import { AuthStoreType } from '../../util/types';

import { useTranslation } from 'react-i18next';

import styles from './Auth.module.css';
import treeLogo from '../../assets/tree.png';
import flags from '../../util/flags';

import Modal from '../../components/Modal/Modal';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';

const Auth = () => {
  const navigate = useNavigate();
  const { userId } = useContext(StoreContext) as AuthStoreType;

  const { t, i18n } = useTranslation();

  const [isLanguageSelectorModalOpen, setIsLanguageSelectorModalOpen] = useState(false);

  useEffect(() => {
    if (userId) {
    navigate('/');
    }
  }, [userId])

  const showChangeLanguageModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsLanguageSelectorModalOpen(true);
  }

  return (
    <div className={styles.authPage}>
      <a
        className={styles.changeLanguageBtn}
        href='#'
        onClick={(e) => showChangeLanguageModal(e)}
      >
        <div className={styles.flag}>
          {flags[i18n.language || 'en']}
        </div>
      </a>
      <div className={styles.authContent}>
       <img src={treeLogo} alt="ProudOf logo" width="110" height="110" title="ProudOf" />
       <h1 data-testid="auth-title">{t('onb-screen1-title')}</h1>
       <h3>{t('onb-screen1-subtitle')}</h3>

       <Outlet />
      </div>

      {isLanguageSelectorModalOpen &&
        <Modal setIsModalOpen={setIsLanguageSelectorModalOpen}>
          <LanguageSelector isModalOpen={setIsLanguageSelectorModalOpen} />
        </Modal>
      }
    </div>
  )
}

export default Auth;