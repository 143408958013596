const fr = {
  "onb-screen1-title": "Bienvenue à ProudOf",
  "onb-screen1-subtitle": "Notre application axée sur le développement personnel",
  "onb-screen1-text": "Cultivez votre amour-propre en ajoutant des entrées et en entretenant votre arbre symbolique du progrès.",

  "onb-screen2-title": "Ajoutez des entrées pour agrandir votre arbre et créer une collection",
  "onb-screen2-subtitle": "Toutes les 15 participations, vous élevez votre arbre vers de nouveaux sommets de réussite.",

  "onb-screen3-title": "Personnalisez votre arbre",
  "onb-screen3-subtitle": "Personnalisez l'apparence de votre arbre avec les modules complémentaires de la boutique.",

  "onb-skip": "Passer",

  // Auth
  "auth-create-account-title": "Créer un compte gratuit",
  "auth-email-address": "Adresse e-mail",
  "auth-password": "Mot de passe",
  "auth-password-repeat": "Répéter le mot de passe",
  
  // Auth err
  "auth-err-invalid-email": "S'il vous plaît, mettez une adresse email valide",
  "auth-err-email-in-use": "Adresse e-mail déjà utilisée",
  "auth-err-blank-password": "Veuillez entrer un mot de passe",
  "auth-err-password-6-char": "Le mot de passe doit contenir au moins 6 caractères",
  "auth-err-passwords-not-same": "Les mots de passe ne sont pas les mêmes",
  "auth-err-invalid-login-details": "Authentification invalide",
  
  "auth-create-account-main-cta": "Créer un compte gratuitement",
  "auth-already-have-account": "Vous avez déjà un compte?",
  "auth-sign-in-cta": "Se connecter",
  
  "auth-terms-part1": "En vous inscrivant, vous indiquez que vous avez lu et accepté les",
  "auth-terms": "Termes et conditions",
  "auth-terms-and": "et",
  "auth-privacy": "Politique de confidentialité",
  
  "auth-signin-title": "Connectez-vous à votre compte",
  "auth-signin-forgot-pass-cta": "Mot de passe oublié?",
  "auth-signin-main-cta": "Se connecter",
  "auth-signin-create-cta": "Vous n'avez pas de compte ? <1>Inscrivez-vous</1> gratuitement",
  
  "auth-forgot-title": "Mot de passe oublié",
  "auth-forgot-main-cta": "Réinitialiser le mot de passe",
  "auth-forgot-already-account": "Vous avez déjà un compte?",
  "auth-forgot-sign-in": "Se connecter",

  "home-main-cta": "Ajouter une entrée",
  "home-secondary-cta": "Entrées passées",
  
  "notification-daily-checkin": "Vous avez reçu {{dailyCheckinHeartsAward}} points pour votre enregistrement quotidien.",
  
  "add-post-title": "Aujourd'hui, je suis fier de:",

  "mon": "LUN",
  "tue": "MAR",
  "wed": "MER",
  "thu": "JEU",
  "fri": "VEN",
  "sat": "SAM",
  "sun": "DIM",

  "jan": "Janvier",
  "feb": "Février",
  "mar": "Mars",
  "apr": "Avril",
  "may": "Mai",
  "jun": "Juin",
  "jul": "Juillet",
  "aug": "Août",
  "sep": "Septembre",
  "oct": "Octobre",
  "nov": "Novembre",
  "dec": "Décembre",

  "loading": "Chargement...",
  
  "posts-no-entries-title": "Il n'y a pas encore d'entrées.",
  "posts-no-entries-cta": "Ajoutez votre première entrée",
  
  "edit-entry-title": "Modifier l'entrée :",
  "edit-entry-delete-cta": "Supprimer l'entrée",
  "edit-entry-delete-modal-title": "Etes-vous sûr de vouloir supprimer cette entrée ?",
  "edit-entry-delete-modal-yes-cta": "Oui",
  "edit-entry-delete-modal-no-cta": "Non",
  
  "tree-collection-title": "Votre collection d'arbres",
  "tree-collection-empty-msg": "Votre collection d'arbres est actuellement vide. Les arbres atteignant leur croissance maximale seront automatiquement enregistrés dans votre collection.",

  "profile-title": "Vos statistiques",
  "profile-total-entries": "Entrées totales",
  "profile-total-day": "Jour",
  "profile-total-days": "Jours",
  "profile-total-trees": "Arbres collectés",
  "profile-tree-status": "Statut actuel de votre arbre",
  "profile-tree-status-1": "Jeune",
  "profile-tree-status-2": "Adulte",
  "profile-tree-status-3": "Séculier",
  "profile-btn-send-feedback": "Envoyer des commentaires",
  "profile-btn-how-it-works": "Comment ça marche",
  "profile-btn-logout": "Se déconnecter",
  "profile-btn-delete-account": "Supprimer le compte",
  "profile-btn-change-language": "Changer de langue",
  "profile-btn-shop": "Boutique",

  // Delete account
  "modal-delete-account-title": "Êtes-vous sûr de vouloir supprimer votre compte?",
  "modal-delete-account-subtitle": "Votre compte et toutes vos données seront supprimés.",
  "modal-delete-account-text": "Entrez votre mot de passe pour supprimer votre compte.",
  "password": "Mot de passe",
  "modal-delete-account-invalid-credentials": "Authentification invalide",
  "modal-delete-account-main-cta": "Supprimer le compte",
  "modal-delete-account-cancel-cta": "Non",
  
  "shop-tab-title": "Boutique",
  "customize-tab-title": "Personnaliser",
  
  "shop-page-title": "Des oiseaux",
  
  "customize-page-save-cta": "Sauvegarder",
  "customize-page-title": "Personnalisez votre arbre",
  "customize-page-no-addon-cta": "Obtenir un accessoire",

  "offline-notification": "Vous êtes hors ligne. Certaines fonctionnalités risquent de ne pas être enregistrées sans une connexion Internet active.",

  "general-error": "Quelque chose s'est mal passé. Veuillez réessayer plus tard.",
  "general-notification-auth-reset-link-sent": "Un lien de réinitialisation a été envoyé à votre adresse e-mail.",

  "profile-no-account-text" : "Vos données ne sont actuellement liées à aucun compte et sont exclusivement accessibles sur cet appareil. En cas de désinstallation de l'application ou de perte de l'appareil, vos données seront irrécupérables. Créez un compte gratuit pour sauvegarder vos données et y accéder depuis d'autres appareils.",

  "auth-slogan": "Embrassez votre histoire: une application de journal pour renforcer en douceur la confiance, favoriser la croissance et le bien-être conscient.",
  "auth-continue-without-account": "Poursuivre sans créer de compte",
  "auth-continue-without-account-note": "Veuillez noter que continuer sans un compte entraînera la perte de vos données lors de la suppression de l'application. Vous pouvez choisir de créer un compte ultérieurement depuis la page de profil.",

  "inspiration-1": "p.ex. J'ai fait une méditation de 15 minutes aujourd'hui.",
  "inspiration-2": "p.ex. J'ai veillé à ne pas sauter le petit-déjeuner ce matin.",
  "inspiration-3": "p.ex. J'ai fait du cardio à la salle de sport, couru 5 km sur le tapis roulant.",
  "inspiration-4": "p.ex. J'ai passé une heure agréable au parc avec mon chien.",
  "inspiration-5": "p.ex. Plutôt que d'opter pour la restauration rapide, j'ai pris le temps de préparer un délicieux dîner.",
  "inspiration-6": "p.ex. Je me suis plongé dans mon nouveau livre, lisant 30 pages.",
  "inspiration-7": "p.ex. J'ai apprécié une conversation téléphonique avec un ami.",
}

export default fr;