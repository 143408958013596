import { useState, useEffect, useContext } from "react";

import {
  getDatabase,
  ref,
  onValue,
  orderByChild,
  query,
} from "firebase/database";

import StoreContext from "../../util/AuthContext";

import { AuthStoreType } from "../../util/types";
import { useTranslation } from "react-i18next";

import Post from "../../components/Post/Post";

import moment from "moment";

const Entries = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [posts, setPosts] = useState<any[]>();

  const { userId } = useContext(StoreContext) as AuthStoreType;
  const { t } = useTranslation();

  useEffect(() => {
    const db = getDatabase();

    const orderedPostsRef = query(
      ref(db, "posts/" + userId),
      orderByChild("negativeTimestamp")
    );

    onValue(orderedPostsRef, (snapshot) => {
      const postsArr = [];
      let entries: { entryId: string, entry: string }[] = [];
      let lastDate = "";
      let lastDateInfo = {};
      setIsLoading(true);

      snapshot.forEach((child) => {
        const postVal = child.val();
        const date = moment(postVal.timestamp).format("DD-MM-YYYY");

        if (!lastDate) {
          lastDate = date;

          lastDateInfo = {
            date: moment(postVal.timestamp).date(),
            day: moment(postVal.timestamp).day(),
            month: moment(postVal.timestamp).month() + 1,
            year: moment(postVal.timestamp).year(),
          };
        }

        if (lastDate === date) {
          entries.push({
            entryId: child.key,
            entry: postVal.post,
          });
        } else {
          lastDate = date;

          postsArr.push({
            ...JSON.parse(JSON.stringify(lastDateInfo)),
            entries: entries.slice(),
          });

          lastDateInfo = {
            date: moment(postVal.timestamp).date(),
            day: moment(postVal.timestamp).day(),
            month: moment(postVal.timestamp).month() + 1,
            year: moment(postVal.timestamp).year(),
          };

          entries = [
            {
              entryId: child.key,
              entry: postVal.post,
            },
          ];
        }
      });

      if (entries.length > 0) {
        postsArr.push({
          ...lastDateInfo,
          entries: entries.slice(),
        });
      }

      setPosts(postsArr);
      setIsLoading(false);
    });
  }, [userId]);

  if (isLoading) {
    return (
      <div style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        {t('loading')}
      </div>
    );
  }

  // [TODO] no posts
  if(!posts || posts.length === 0) {
    return (
      <div>
        <h2>{t('posts-no-entries-title')}</h2>
        {t('posts-no-entries-cta')}
      </div>
    );
  }
  
  let lastYear = "";
  let lastMonth = "";
  let subtitle = false;

  return (
    <div className="subpage-wrap">
      <div className="subpage">
        {posts.map((post:any, index: any) => {
          if (lastYear !== post.year || lastMonth !== post.month) {
            lastYear = post.year;
            lastMonth = post.month;
            subtitle = true;
          } else {
            subtitle = false;
          }
          return (<Post key={index} post={post} subtitle={subtitle} />)
        })}
      </div>
    </div>
  )
}

export default Entries;