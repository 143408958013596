import styles from './Modal.module.css';

import { IoIosClose } from "react-icons/io";

const Modal = ({setIsModalOpen, children} : {setIsModalOpen: Function, children: any}) => {
  const closeModal = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsModalOpen(false);
  }
  return (
    <div className={styles.modal}>
      <div className={styles.outer} onClick={closeModal}></div>
      <div className={styles.contentWrap}>
        <a href="#" className={styles.btnClose} onClick={closeModal}><IoIosClose size="30" /></a>
        <div className={styles.content}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Modal;