import { useState, useContext } from 'react';

import { getAuth, deleteUser, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { getDatabase, ref, update } from "firebase/database";

import AuthContext from '../../util/AuthContext';
import { AuthStoreType } from '../../util/types';

import { useTranslation } from 'react-i18next';

import styles from './DeleteAccount.module.css';

import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import Spinner from '../Spinner/Spinner';

const DeleteAccount = ({ isModalOpen }: { isModalOpen: Function }) => {

  const auth = getAuth();
  const user = auth.currentUser;

  const [isLoadingDeleteAccount, setIsLoadingDeleteAccount] = useState(false);

  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const { userId } = useContext(AuthContext) as AuthStoreType;

  const db = getDatabase();
  const { t } = useTranslation();

  const closeModal = () => {
    isModalOpen(false);
    return ;
  }

  const deleteAccount = async () => {
    const updates: {[key: string]: null} = {};

    updates[`posts/${userId}`] = null;
    updates[`trees/${userId}`] = null;
    updates[`users/${userId}`] = null;
    
    update(ref(db), updates);

    if ( user ) {
      deleteUser(user).then(() => {
        // User deleted.
        // [TODO] check what happens
      }).catch((error) => {
        // An error ocurred
        // [TODO] alert error
      });
    }
  }

  const reevaluateUserAndDelete = () => {

    setIsLoadingDeleteAccount(true);

    if (password.trim().length <= 0) {
      setPasswordErr(true);
      setIsLoadingDeleteAccount(false);
      return;
    }
    
    if (user) {
      const emails = user.email || "";

      const credential = EmailAuthProvider.credential(
        emails,
        password
      );

      // reautentication of user is needed before deletion
      reauthenticateWithCredential(auth.currentUser, credential).then(() => {
        deleteAccount();
        closeModal();
        // User re-authenticated.
      }).catch((error) => {
        // [TODO] error
        setPasswordErr(true);
        setIsLoadingDeleteAccount(false);
      });
    }
  }

  const togglePassVisibility = () => {
    setShowPassword(!showPassword);
  }

  return (
    <div className={styles.wrapper}>
      <h3>{t('modal-delete-account-title')}</h3>
      <h4>{t('modal-delete-account-subtitle')}</h4>

      <p>{t('modal-delete-account-text')}</p>

      <div className={styles.inputWrap}>
        <input
          maxLength={40}
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          autoCapitalize='none'
          placeholder={t('password')}
          type={showPassword ? 'text' : 'password'}
        />

        <span className={styles.showPasswordBtn} onClick={togglePassVisibility}>
          {showPassword 
          ?
            <IoIosEye size={22} />
          :
            <IoIosEyeOff size={22} />
          }
        </span>
      </div>

      {passwordErr &&
        <p className={styles.err}>
          {t('modal-delete-account-invalid-credentials')}
        </p>
      }

      <div className={styles.buttonsWrap}>
        <button
          className={styles.btnDeleteAccount}
          onClick={reevaluateUserAndDelete}
          disabled={isLoadingDeleteAccount ? true : false}
        >
          {isLoadingDeleteAccount &&
            <Spinner />
          }
            
          {t('modal-delete-account-main-cta')}
        </button>

        <button
          className={styles.secondaryCta}
          onClick={closeModal}>{t('modal-delete-account-cancel-cta')}
        </button>
      </div>
    </div>
  )
}

export default DeleteAccount;