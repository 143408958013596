import { useState } from "react";
import styles from './Forgot.module.css';

import { useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";

import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { useTranslation } from "react-i18next";

const Forgot = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [email, setEmail] = useState('');
  const [emailErr, setEmailErr] = useState(false);

  const auth = getAuth();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const resetPassword = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setIsLoading(true);

    let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    let err = false;

    if (emailReg.test(email.trim()) === false) {
      setIsLoading(false);
      err = true;
      setEmailErr(true);
    }

    if (!err) {
      sendPasswordResetEmail(auth, email.trim())
        .then(() => {
          navigate('/auth/login');
          // [TODO] general message
          // setGeneralError(t('general-notification-auth-reset-link-sent'));
          setIsLoading(false);
        })
        .catch((error: any) => {
          const errorMessage = error.message;
          // [TODO] general message
          // setGeneralError(errorMessage);
          setIsLoading(false);
        });
    }
  }

  return (
    <div className={styles.forgot}>
      <h2>{t('auth-forgot-title')}</h2>

      <form className={styles.createAccount} onSubmit={e => resetPassword(e)}>
        <div className={`${styles.inputWrap} ${(emailErr) ? styles.error : ''}`}>
          <input
            type="text"
            value={email}
            placeholder={t('auth-email-address')}
            onChange={(e) => setEmail(e.target.value)}
          />

          {emailErr &&
            <p className={styles.errorMsg}>{t('auth-err-invalid-email')}</p>
          }
        </div>

        <button
          type="submit"
          disabled={isLoading ? true : false}
        >{t('auth-forgot-main-cta')}</button>
      </form>

      <p className={styles.footerCopy}>{t('auth-forgot-already-account')} <Link to="/auth/login">{t('auth-forgot-sign-in')}</Link></p>
    </div>
  )
}

export default Forgot;