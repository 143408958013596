import { InspirationType } from './types';

const inspiration: InspirationType = {
  1: 'inspiration-1',
  2: 'inspiration-2',
  3: 'inspiration-3',
  4: 'inspiration-4',
  5: 'inspiration-5',
  6: 'inspiration-6',
  7: 'inspiration-7',
}

export default inspiration;